@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.social_media_icons {
  height: 100px;
  width: 100px;
}

select > option {
  padding: 13px !important ;
}

.content {
  background: inherit;
  display: none;
}

.active-content {
  display: block;
}

.div-tag {
  display: none;
}

.a-tag:hover + .div-tag {
  display: block;
}

.more-info-modal {
  z-index: 1000 !important;
}

.pinterestmodal {
  z-index: 1000 !important;
}
.published_social_icon {
  z-index: 1000 !important;
}

.active {
  color: #2f9dbca5;
  font-weight: bold;
  border-radius: 10px 10px 10px;
}

.active .subscription_box {
  color: #2f9dbca5;
  color: white !important;
  border-radius: 10px 10px 10px;
}

@media screen and (min-width: 600px) {
  .active {
     background: rgb(1,94,117);
background: linear-gradient(90deg, rgba(1,94,117,1) 0%, rgba(90,167,188,1) 35%, rgba(57,198,233,1) 100%);
    color: white !important;
    border-radius: 10px 10px 10px;
  }
  .active:hover {
   background: rgb(1,94,117);
background: linear-gradient(90deg, rgba(1,94,117,1) 0%, rgba(90,167,188,1) 35%, rgba(57,198,233,1) 100%);
    color: white !important;
    border-radius: 10px 10px 10px;
  }
}

.dashboard_topbar {
  z-index: 1000 !important;
}

.mobile_nav {
  z-index: 1000 !important;
}

.subscription_modal {
  z-index: 1000 !important;
}
.share_location {
  z-index: 1000 !important;
}

.access_modal {
  z-index: 2000 !important;
}
